<template>
  <div class="company-update page-layout">
    <PageHeader
      title="Informations de l'entreprise"
      :has-back="true"
      :has-actions="true"
      @change-tab="changeTab"
      @back="onGlobalCancel"
    >
      <md-button
        v-if="canEditInfo"
        class="md-raised md-primary"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click.native="openConfirmTrashModal"
      >
        Supprimer cette entreprise
      </md-button>
    </PageHeader>

    <LoadingCard v-if="isLoading" />

    <!-- ## Les informations de l'entreprise ## -->
    <md-card
      v-if="canEditInfo"
      class="company-update__card md-layout-item md-size-100 md-small-size-100"
    >
      <!-- le formulaire de création -->
      <md-card-content>
        <h3>Informations</h3>
        <div class="meep-input">
          <!-- Nom -->
          <md-field :class="{ 'md-invalid': errors.has('name') }">
            <label>{{ $t("update-company.name") }}</label>
            <md-input
              v-model="newInfos.name"
              v-validate="{
                required: true,
                regex: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _ & . -]*)$/,
              }"
              name="name"
              type="text"
            />
            <span v-show="errors.has('name')" class="md-error">
              {{ errors.first("name") }}
            </span>
          </md-field>
        </div>

        <!-- Numéro de SIREN -->
        <div class="meep-input">
          <md-field
            v-if="isAdmin"
            :class="{ 'md-invalid': errors.has('siren') }"
          >
            <label>{{ $t("update-company.siren") }}</label>

            <md-input
              v-model="newInfos.siren"
              v-validate="'numeric|min:9|max:9'"
              name="siren"
              type="text"
            />

            <span v-show="errors.has('siren')" class="md-error">
              {{ errors.first("siren") }}
            </span>
          </md-field>
        </div>

        <!-- Nom et prénom du dirigeant -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('dirigeant') }">
            <label>{{ $t("update-company.dirigeant") }}</label>

            <md-input
              v-model="newInfos.dirigeant"
              v-validate="'alpha_spaces'"
              name="dirigeant"
              type="text"
            />

            <span v-show="errors.has('dirigeant')" class="md-error">
              {{ errors.first("dirigeant") }}
            </span>
          </md-field>
        </div>

        <!-- Email -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>{{ $t("update-company.email") }}</label>

            <md-input
              v-model="newInfos.email"
              v-validate="'email'"
              name="email"
              type="email"
            />

            <span v-show="errors.has('email')" class="md-error">
              {{ errors.first("email") }}
            </span>
          </md-field>
        </div>

        <!-- Numéro de téléphone -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('tel') }">
            <label>{{ $t("update-company.tel") }}</label>

            <md-input
              v-model="newInfos.tel"
              v-validate="'telephone'"
              name="tel"
              type="tel"
            />

            <span v-show="errors.has('tel')" class="md-error">
              {{ errors.first("tel") }}
            </span>
          </md-field>
        </div>

        <!-- Site Web -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('url') }">
            <label>{{ $t("update-company.url") }}</label>

            <md-input
              v-model="newInfos.url"
              v-validate="'url'"
              name="url"
              type="url"
            />

            <span v-show="errors.has('url')" class="md-error">
              {{ errors.first("url") }}
            </span>
          </md-field>
        </div>

        <!-- Numéro et voie -->
        <div class="meep-input">
          <md-field>
            <label>{{ $t("update-company.adresse") }}</label>
            <md-input v-model="newInfos.adresse" name="adresse" type="text" />
          </md-field>
        </div>

        <!-- Ville -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('ville') }">
            <label>{{ $t("update-company.ville") }}</label>

            <md-input
              v-model="newInfos.ville"
              v-validate="'alpha_spaces'"
              name="ville"
              type="ville"
            />

            <span v-show="errors.has('ville')" class="md-error">
              {{ errors.first("ville") }}
            </span>
          </md-field>
        </div>

        <!-- Code postal -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
            <label>{{ $t("update-company.codepostal") }}</label>
            <md-input
              v-model="newInfos.codepostal"
              v-validate="'numeric|min:5|max:5'"
              name="codepostal"
              type="text"
            />

            <span v-show="errors.has('codepostal')" class="md-error">
              {{ errors.first("codepostal") }}
            </span>
          </md-field>
        </div>

        <!-- Numéro et voie -->
        <div v-if="hasEmailSynchro" class="meep-input">
          <md-field
            :class="{ 'md-invalid': errors.has('cloud_synchronize_email') }"
          >
            <label>{{ $t("company.cloud_synchronize_email") }}</label>
            <md-input
              v-model="newInfos.cloud_synchronize_email"
              v-validate="'email'"
              name="cloud_synchronize_email"
              type="email"
            />
            <span
              v-show="errors.has('cloud_synchronize_email')"
              class="md-error"
            >
              {{ errors.first("cloud_synchronize_email") }}
            </span>
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions>
        <!-- Le bouton annuler -->
        <md-button
          :class="{
            'md-primary--inverted-outline': !isJEP,
            'md-alternate--inverted-outline': isJEP,
            'md-accent': isInfosModified,
            'meep-disabled': !isInfosModified,
          }"
          :disabled="!isInfosModified"
          @click.native="onInfosCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-raised"
          :class="{
            'md-primary': isInfosModified,
            'meep-disabled': !isInfosModified,
          }"
          :disabled="!isInfosModified"
          @click.native="onInfosSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <md-card
      v-else
      class="company-update__card md-layout-item md-size-100 md-small-size-100"
    >
      <!-- Le titre -->
      <md-card-header>
        <h3>Informations</h3>
        <div class="md-title">
          {{ company.name }}
        </div>
        <!-- Numéro de SIREN -->
        <div class="md-subhead">Numéro de SIREN : {{ company.siren }}</div>
      </md-card-header>
    </md-card>

    <!-- ## Les groupes ## -->
    <md-card
      class="company-update__card md-layout-item md-size-100 md-small-size-100"
    >
      <md-card-content>
        <h3>Groupes</h3>
        <md-button
          class="md-primary--inverted-outline"
          @click.native="openSelectGroupesModal"
        >
          Assigner un ou plusieurs groupes
        </md-button>

        <md-list v-if="newGroupes.length > 0">
          <md-list-item v-for="(groupe, index) in newGroupes" :key="index">
            <div class="md-list-item-text md-primary--inverted">
              {{ groupe.name }}
            </div>

            <md-button
              class="md-icon-button md-list-action"
              @click="newGroupes.splice(index, 1)"
            >
              <md-icon class="md-primary--inverted"> cancel </md-icon>
            </md-button>
          </md-list-item>
        </md-list>

        <!-- À afficher lorqu'il n'y a aucun groupes -->
        <md-empty-state
          v-else
          md-description="Cette entreprise ne fait partie d'aucun groupe"
        />
      </md-card-content>

      <md-card-actions>
        <!-- Le bouton annuler -->
        <md-button
          class="md-primary--inverted-outline"
          :class="{
            'md-accent': isGroupesModified,
            'meep-disabled': !isGroupesModified,
          }"
          :disabled="!isGroupesModified"
          @click.native="onGroupesCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-primary"
          :class="{
            'md-primary': isGroupesModified,
            'meep-disabled': !isGroupesModified,
          }"
          :disabled="!isGroupesModified"
          @click.native="onGroupesSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <!-- ## Les collaborateurs ## -->
    <md-card
      class="company-update__card md-layout-item md-size-100 md-small-size-100"
    >
      <md-card-content>
        <h3>Collaborateurs</h3>
        <md-button
          v-if="canEditInfo"
          class="md-primary--inverted-outline"
          @click.native="openSelectCollabsModal"
        >
          Ajouter des collaborateurs
        </md-button>
        <!-- La liste des collaborateurs -->
        <md-table v-if="newCollabs.length">
          <!-- Le table head -->
          <md-table-row>
            <md-table-head style="width: 25%;">Prénom</md-table-head>

            <md-table-head>Nom</md-table-head>

            <md-table-head v-if="canEditInfo">
              <md-icon>cloud</md-icon>
              <md-tooltip>{{
                $t("companies.collabs.cloud-tooltip")
              }}</md-tooltip>
            </md-table-head>

            <md-table-head v-if="canEditInfo">
              <md-icon>assignment</md-icon>
              <md-tooltip>{{
                $t("companies.collabs.assignment-tooltip")
              }}</md-tooltip>
            </md-table-head>

            <md-table-head v-if="canEditInfo">
              <md-icon>chat</md-icon>
              <md-tooltip>{{
                $t("companies.collabs.chat-tooltip")
              }}</md-tooltip>
            </md-table-head>
          </md-table-row>

          <!-- Les lignes de la table -->
          <md-table-row v-for="(collab, index) in newCollabs" :key="index">
            <!-- Prénom -->
            <md-table-cell>{{ collab.firstname }}</md-table-cell>

            <!-- Nom -->
            <md-table-cell>{{ collab.lastname }}</md-table-cell>

            <!-- Peut écrire dans le cloud -->
            <md-table-cell v-if="canEditInfo">
              <md-checkbox
                v-model="collab.can_write_in_cloud"
                :disabled="$store.state.self.id === collab.id"
                @change="$forceUpdate()"
              >
                <!-- forceUpdate dégeulasse parce que décidément les checkbox...
								avec un v-model booleen, ici lorsque l'on clique le re-render ne se
                fait pas automatiquement-->
              </md-checkbox>
              <md-tooltip>{{
                $t("companies.collabs.can_write_in_cloud-tooltip")
              }}</md-tooltip>
            </md-table-cell>

            <!-- Peut modifier les informations -->
            <md-table-cell v-if="canEditInfo">
              <md-checkbox
                v-model="collab.can_modify_infos"
                :disabled="$store.state.self.id === collab.id"
                @change="$forceUpdate()"
              />
              <md-tooltip>{{
                $t("companies.collabs.can_modify_infos-tooltip")
              }}</md-tooltip>
            </md-table-cell>

            <!-- Peut parler dans le chat -->
            <md-table-cell v-if="canEditInfo">
              <md-checkbox
                v-model="collab.can_speak_in_chat"
                :disabled="$store.state.self.id === collab.id"
                @change="$forceUpdate()"
              />
              <md-tooltip>{{
                $t("companies.collabs.can_speak_in_chat-tooltip")
              }}</md-tooltip>
            </md-table-cell>

            <!-- Retirer de la liste -->
            <md-table-cell v-if="canEditInfo">
              <md-button
                v-if="$store.state.self.id !== collab.id"
                class="md-icon-button"
                @click.stop="newCollabs.splice(index, 1)"
              >
                <md-icon>clear</md-icon>
              </md-button>

              <md-tooltip v-if="$store.state.self.id !== collab.id">
                Retirer ce collaborateur
              </md-tooltip>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <!-- À afficher lorqu'il n'y a personne -->
        <md-empty-state
          v-else
          md-description="Aucun collaborateur n'a été assigné à cette entreprise"
        />
      </md-card-content>

      <md-card-actions v-if="canEditInfo">
        <!-- Le bouton annuler -->
        <md-button
          class="md-primary--inverted-outline"
          :class="{
            'md-accent': isCollabsModified,
            'meep-disabled': !isCollabsModified,
          }"
          :disabled="!isCollabsModified"
          @click.native="onCollabsCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-primary"
          :class="{
            'md-primary': isCollabsModified,
            'meep-disabled': !isCollabsModified,
          }"
          :disabled="!isCollabsModified"
          @click.native="onCollabsSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <!-- ## Les utilisateurs ## -->
    <md-card
      class="company-update__card md-layout-item md-size-100 md-small-size-100"
    >
      <md-card-content>
        <h3>Utilisateurs</h3>
        <md-button
          class="md-primary--inverted-outline"
          @click.native="openSelectUsersModal"
        >
          Ajouter des comptes
        </md-button>

        <!-- La liste des collaborateurs -->
        <md-table v-if="newUsers.length">
          <!-- Le table head -->
          <md-table-row>
            <md-table-head>Prénom</md-table-head>

            <md-table-head>Nom</md-table-head>

            <md-table-head>Statut</md-table-head>

            <md-table-head v-if="canEditInfo">
              <md-icon>web</md-icon>
              <md-tooltip>{{ $t("companies.clients.web-tooltip") }}</md-tooltip>
            </md-table-head>
          </md-table-row>

          <!-- Les lignes de la table -->
          <md-table-row
            v-for="(user, index) in newUsers"
            :key="index"
            @click.native="viewUser(user)"
          >
            <!-- Prénom -->
            <md-table-cell>
              {{ user.firstname }}
            </md-table-cell>

            <!-- Nom -->
            <md-table-cell>
              {{ user.lastname }}
            </md-table-cell>

            <!-- Statut -->
            <md-table-cell>
              {{ $$filters.formatScope(user.scope) }}
            </md-table-cell>

            <!-- Peut parler dans le chat -->
            <md-table-cell v-if="canEditInfo">
              <md-checkbox
                v-model="user.has_web_access"
                :disabled="user.scope !== 5"
                @change="$forceUpdate()"
              />
              <md-tooltip>Peut connecter à l'espace client</md-tooltip>
            </md-table-cell>

            <!-- Retirer de la liste -->
            <md-table-cell>
              <md-button
                v-if="isAdmin || isCollab || user.scope === 5"
                class="md-icon-button"
                @click.stop="newUsers.splice(index, 1)"
              >
                <md-icon>clear</md-icon>
              </md-button>
              <md-tooltip v-if="isAdmin || isCollab || user.scope === 5">
                Retirer cet utilisateur
              </md-tooltip>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <!-- À afficher lorqu'il n'y a personne -->
        <md-empty-state
          v-else
          md-description="Aucun compte n'a été lié à cette entreprise"
        />
      </md-card-content>

      <md-card-actions>
        <!-- Le bouton annuler -->
        <md-button
          class="md-primary--inverted-outline"
          :class="{
            'md-accent': isUsersModified,
            'meep-disabled': !isUsersModified,
          }"
          :disabled="!isUsersModified"
          @click.native="onUsersCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-primary"
          :class="{
            'md-primary': isUsersModified,
            'meep-disabled': !isUsersModified,
          }"
          :disabled="!isUsersModified"
          @click.native="onUsersSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <select-items-modal
      v-if="isSelectGroupesModalOpen"
      :items-list="groupesCatalog"
      :selected-list="newGroupes"
      :text="selectGroupesModalText"
      @close="closeSelectGroupesModal"
      @saved="onGroupesSelected"
    />

    <select-items-modal
      v-if="isSelectCollabsModalOpen"
      :items-list="collabCatalog"
      :selected-list="newCollabs"
      :text="selectCollabsModalText"
      @close="closeSelectCollabsModal"
      @saved="onCollabsSelected"
    />

    <select-items-modal
      v-if="isSelectUsersModalOpen"
      :items-list="usersCatalog"
      :selected-list="newUsers"
      :text="selectUsersModalText"
      @close="closeSelectUsersModal"
      @saved="onUsersSelected"
    />

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmGlobalCancelModal"
      @confirm="goBack"
    />

    <confirm-action-modal
      v-if="isConfirmTrashModalOpen"
      :text="confirmTrashModalText"
      :object-to-act-upon="company"
      @close="closeConfirmTrashModal"
      @confirm="onTrash"
    />
  </div>
</template>

<script>
/* Modèles */
import companiesModel from "../../model/companies";
import usersModel from "../../model/users";
import groupesModel from "../../model/groupes";

/* Composants */
import selectItemsModal from "../../components/modal/select-items";
import confirmActionModal from "../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import LoadingCard from "@/components/LoadingCard";
import { sortByKey } from "@/services/util";
import _ from "lodash";

export default {
  name: "UpdateCompany",

  components: {
    PageHeader,
    "select-items-modal": selectItemsModal,
    "confirm-action-modal": confirmActionModal,
    LoadingCard,
  },

  data() {
    return {
      isLoading: false,
      error: false,
      /* On se sert des mêmes fonctions pour cancel et l'initialisation dans beforeMounted, alors on utilise ce flag pour éviter que des toast n'apparaissent tout de suite lorsque l'utilisateur entre sur la page */
      canShowToastedMessages: false,

      /* Informations de l'entreprise */
      company: {},

      /* Tampons pour éditer les informations */
      newInfos: {},
      newGroupes: [],
      newCollabs: [],
      newUsers: [],

      /* Si les modals sont ouverts */
      isSelectGroupesModalOpen: false,
      isSelectCollabsModalOpen: false,
      isSelectUsersModalOpen: false,
      isConfirmGlobalCancelModalOpen: false,
      isConfirmTrashModalOpen: false,

      /* Les listes qui seront passées dans les selectItemsModals */
      groupesCatalog: [],
      collabsCatalog: [],
      usersCatalog: [],

      /* Texte des modals */
      selectCollabsModalText: {
        header: "Selectionner les collaborateurs",
      },

      selectGroupesModalText: {
        header: "Selectionner les groupes",
      },

      selectUsersModalText: {
        header: "Selectionner les comptes",
      },

      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },

      confirmTrashModalText: {
        header: "Suppression d'une entreprise",
        body(company) {
          return (
            "Vous êtes sur le point de supprimer l'entreprise <b>" +
            company.name +
            "</b>"
          );
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },

      currentTab: "",
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "self", "isJEP"]),

    hasEmailSynchro() {
      return this.$store.state.self.organization.has_email_synchro;
    },

    isInfosModified() {
      if (this.company.name !== this.newInfos.name) return true;
      if (this.company.siren !== this.newInfos.siren) return true;
      if (this.company.tel !== this.newInfos.tel) return true;
      if (this.company.adresse !== this.newInfos.adresse) return true;
      if (this.company.codepostal !== this.newInfos.codepostal) return true;
      if (this.company.ville !== this.newInfos.ville) return true;
      if (this.company.email !== this.newInfos.email) return true;
      if (this.company.url !== this.newInfos.url) return true;
      if (
        this.company.cloud_synchronize_email !==
        this.newInfos.cloud_synchronize_email
      )
        return true;

      return this.company.dirigeant !== this.newInfos.dirigeant;
    },

    isCollabsModified() {
      if (!this.company || !this.company.collaborators) {
        return false;
      }

      /* Des noms de variables plus courts */
      let newC = this.newCollabs;
      let oldC = this.company.collaborators;

      /* Si les deux listes n'ont pas la même taille,
       ** elles ont forcément été modifiées */
      if (newC.length !== oldC.length) return true;

      /* Si les listes ont la même taille, les trier pour les comparer */
      const comparator = (a, b) => {
        if (a.firstname < b.firstname) return -1;
        if (a.firstname > b.firstname) return 1;
        return 0;
      };

      newC.sort(comparator);
      oldC.sort(comparator);

      /* Comparer */
      for (let i = 0; i < newC.length; i++) {
        /* Des personnes ont été ajoutées/retirées */
        if (newC[i].id !== oldC[i].id) {
          return true;
        }

        /* Des droits ont étés modifiés */
        if (
          newC[i].can_write_in_cloud !== oldC[i].can_write_in_cloud ||
          newC[i].can_modify_infos !== oldC[i].can_modify_infos ||
          newC[i].can_speak_in_chat !== oldC[i].can_speak_in_chat
        ) {
          return true;
        }

        /* Les droits d'une personne n'ont pu être mis à jour */
        if (newC[i].isFailed) {
          return true;
        }
      }

      /* Les deux listes sont bel et bien les mêmes */
      return false;
    },

    isUsersModified() {
      if (!this.company || !this.company.users) {
        return false;
      }

      /* Des noms de variables plus courts */
      let newU = this.newUsers;
      let oldU = this.company.users;

      /* Si les deux listes n'ont pas la même taille,
       ** elles ont forcément été modifiées */
      if (newU.length !== oldU.length) return true;

      /* Si les listes ont la même taille, les trier pour les comparer */
      const comparator = (a, b) => {
        if (a.firstname < b.firstname) return -1;
        if (a.firstname > b.firstname) return 1;
        return 0;
      };

      newU.sort(comparator);
      oldU.sort(comparator);

      /* Comparer */
      for (let i = 0; i < newU.length; i++) {
        const newUser = newU[i];
        const oldUser = oldU[i];

        /* Des personnes ont été ajoutées/retirées */
        if (
          newUser.id !== oldUser.id ||
          newUser.has_web_access !== oldUser.has_web_access
        ) {
          return true;
        }

        if (newUser.isFailed) {
          return true;
        }
      }

      /* Les deux listes sont bel et bien les mêmes */
      return false;
    },

    isGroupesModified() {
      if (!this.company || !this.company.groupes) {
        return false;
      }

      /* Des noms de variables plus courts */
      let newG = this.newGroupes;
      let oldG = this.company.groupes;

      /* Si les deux listes n'ont pas la même taille,
       ** elles ont forcément été modifiées */
      if (newG.length !== oldG.length) return true;

      /* Si les listes ont la même taille, les trier pour les comparer */
      const comparator = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };

      newG.sort(comparator);
      oldG.sort(comparator);

      /* Comparer */
      for (let i = 0; i < newG.length; i++) {
        /* Des groupes ont été ajoutées/retirées */
        if (newG[i].id !== oldG[i].id) {
          return true;
        }

        if (newG[i].isFailed) {
          return true;
        }
      }

      /* Les deux listes sont bel et bien les mêmes */
      return false;
    },

    canEditInfo() {
      return this.isCollab || this.isAdmin;
    },
  },

  watch: {
    $route: "loadCompany",
  },

  created() {
    this.loadCompany();
  },

  methods: {
    changeTab(tabId) {
      this.currentTab = tabId;
    },

    goBack() {
      window.history.back();
    },

    onGlobalSave() {
      if (this.isInfosModified) {
        this.onInfosSave();
      }

      if (this.isGroupesModified) {
        this.onGroupesSave();
      }

      if (this.isCollabsModified) {
        this.onCollabsSave();
      }

      if (this.isUsersModified) {
        this.onUsersSave();
      }
    },

    onGlobalCancel() {
      if (
        this.isInfosModified ||
        this.isGroupesModified ||
        this.isCollabsModified ||
        this.isUsersModified
      ) {
        this.openConfirmGlobalCancelModal();
      } else {
        this.goBack();
      }
    },

    async onInfosSave() {
      try {
        const result = await this.$validator.validateAll();

        if (!result) {
          this.$toasted.global.AppError({
            message: "Vous devez remplir les champs manquants",
          });

          return;
        }

        await companiesModel.update(this.newInfos);

        this.company.name = this.newInfos.name;
        this.company.siren = this.newInfos.siren;
        this.company.tel = this.newInfos.tel;
        this.company.adresse = this.newInfos.adresse;
        this.company.codepostal = this.newInfos.codepostal;
        this.company.ville = this.newInfos.ville;
        this.company.email = this.newInfos.email;
        this.company.url = this.newInfos.url;
        this.company.dirigeant = this.newInfos.dirigeant;
        this.company.cloud_synchronize_email = this.newInfos.cloud_synchronize_email;

        this.$toasted.global.AppSucces({
          message: "Les informations de l'entreprise ont bien été mise à jour",
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onInfosCancel() {
      this.newInfos = {
        id: this.company.id,
        name: this.company.name,
        siren: this.company.siren,
        tel: this.company.tel,
        adresse: this.company.adresse,
        codepostal: this.company.codepostal,
        ville: this.company.ville,
        email: this.company.email,
        url: this.company.url,
        dirigeant: this.company.dirigeant,
        cloud_synchronize_email: this.company.cloud_synchronize_email,
      };

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onCollabsSelected(collabs) {
      /* Retirer les collaborateurs que l'on ne trouve pas dans la nouvelle liste */
      this.newCollabs = this.newCollabs.filter((collab) =>
        collabs.find((element) => element.id === collab.id)
      );

      /* Filtrer les collaborateurs qui sont déjà dans l'ancienne liste pour ne pas niquer leurs droits */
      collabs = collabs.filter((collab) => {
        return !(
          this.newCollabs.find((element) => {
            return element.id === collab.id;
          }) !== undefined
        );
      });

      this.newCollabs = this.newCollabs.concat(collabs);
    },

    async onCollabsSave() {
      let success = true;

      /* Filtrer les utilisateurs à qui retirer les droits */
      const oldCollabs = this.company.collaborators.filter((collab) => {
        return !(
          this.newCollabs.find((element) => {
            return element.id === collab.id;
          }) !== undefined
        );
      });

      // Bulk update
      await Promise.all(
        oldCollabs.map((collab) => {
          return usersModel.removeCollabRights(collab.id, this.company.id);
        })
      ).catch((err) => {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      });

      /* Retirer les droits */
      this.company.collaborators = [];

      /* Mettre à jour les droits */
      await Promise.all(
        this.newCollabs.map(async (collab) => {
          /* Ne pas mettre à jour sois-même */
          if (collab.id === this.$store.state.self.id) {
            collab.isFailed = false;
            this.company.collaborators.push(Object.assign({}, collab));
            return;
          }
          try {
            await usersModel.updateCollabRights(collab, this.company.id);
            collab.isFailed = false;

            /* On utilise assign() pour que les informations modifiées dans le tampon ne soient pas modifiées dans le backup */
            this.company.collaborators.push(Object.assign({}, collab));
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });

            collab.isFailed = true;
            this.company.collaborators.push(Object.assign({}, collab));

            success = false;
          }
        })
      );

      if (success) {
        this.$toasted.global.AppSucces({
          message: "Les droits des collaborateurs ont bien été mis à jour",
        });
      }
    },

    onCollabsCancel() {
      this.newCollabs = [];

      this.newCollabs = _.cloneDeep(this.company.collaborators);

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onUsersSelected(users) {
      this.newUsers = users.map((user) => {
        if (user.scope !== 5) {
          user.has_web_access = true;
        }

        return user;
      });
    },

    async onUsersSave() {
      let success = true;

      /* Filtrer les utilisateurs à virer */
      const oldUsers = this.company.users.filter(
        (user) => !this.newUsers.some((element) => element.id === user.id)
      );

      /* virer les utilisateurs */
      await Promise.all(
        oldUsers.map(async (user) => {
          try {
            await usersModel.removeCompany(user.id, this.company.id);
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          }
        })
      );

      /* lier les utilisateurs*/
      await Promise.all(
        this.newUsers.map(async (user) => {
          try {
            await usersModel.addCompany(
              user.id,
              this.company.id,
              user.has_web_access
            );

            user.isFailed = false;
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });

            user.isFailed = true;
            success = false;
          }
        })
      );

      if (success) {
        this.company.users = _.cloneDeep(this.newUsers);

        this.$toasted.global.AppSucces({
          message: "Les utilisateurs ont bien été mis à jour",
        });
      }
    },

    onUsersCancel() {
      this.newUsers = _.cloneDeep(this.company.users);

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onGroupesSelected(groupes) {
      this.newGroupes = groupes;
    },

    onGroupesSave() {
      let success = true;
      let counter = 0;

      /* Filtrer les groupes à quitter */
      let oldGroupes = this.company.groupes.filter((groupe) => {
        return !(
          this.newGroupes.find((element) => {
            return element.id === groupe.id;
          }) !== undefined
        );
      });

      /* Quitter les groupes */
      oldGroupes.forEach((groupe) => {
        groupesModel.removeCompany(groupe.id, this.company.id).catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
      });

      /* Filtrer les groupes à rejoindre */
      let newGroupes = this.newGroupes.filter((groupe) => {
        return !(
          this.company.groupes.find((element) => {
            return element.id === groupe.id;
          }) !== undefined
        );
      });

      let length = newGroupes.length;

      /* Rejoindre les groupes */
      newGroupes.forEach((groupe) => {
        groupesModel
          .addCompany(groupe.id, this.company.id)
          .then(() => {
            groupe.isFailed = false;
            counter++;
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });

            groupe.isFailed = true;
            counter++;
            success = false;
          });
      });

      /* Wait for the forEach to finish before showing success message */
      const waitForEnd = () => {
        if (counter < length) {
          setTimeout(waitForEnd, 100);
        } else {
          if (success) {
            this.$toasted.global.AppSucces({
              message: "Les groupes ont bien été mis à jour",
            });
          }

          this.company.groupes = this.newGroupes.slice();
        }
      };

      setTimeout(waitForEnd(), 100);
    },

    onGroupesCancel() {
      this.newGroupes = _.cloneDeep(this.company.groupes);

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onTrash() {
      companiesModel
        .putInTrash(this.company.id)
        .then(() => {
          this.$router.push("/dashboard/setting/companies/");
          this.$toasted.global.AppSucces({
            message:
              "L'entreprise '" + this.company.name + "' à bien été supprimée",
          });
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    /* ## MODALS ##  */

    openSelectGroupesModal() {
      groupesModel
        .getAll()
        .then((groupes) => {
          this.groupesCatalog = sortByKey(groupes, "name");
          this.isSelectGroupesModalOpen = true;
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    closeSelectGroupesModal() {
      this.isSelectGroupesModalOpen = false;
    },

    openSelectCollabsModal() {
      usersModel
        .getCollabs()
        .then((collabs) => {
          this.collabCatalog = sortByKey(collabs, "firstname");

          this.collabCatalog.forEach((user) => {
            user.name = user.firstname + " " + user.lastname;
          });

          this.isSelectCollabsModalOpen = true;
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    closeSelectCollabsModal() {
      this.isSelectCollabsModalOpen = false;
    },

    openSelectUsersModal() {
      usersModel
        .getOthers(this.$route.params.id)
        .then((users) => {
          console.log(users);
          this.usersCatalog = sortByKey(users, "firstname");
          this.usersCatalog.forEach((user) => {
            user.name =
              user.firstname +
              " " +
              user.lastname +
              " - " +
              this.$$filters.formatScope(user.scope);
          });

          this.isSelectUsersModalOpen = true;
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    closeSelectUsersModal() {
      this.isSelectUsersModalOpen = false;
    },

    openConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },
    closeConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },

    openConfirmTrashModal() {
      this.isConfirmTrashModalOpen = true;
    },

    closeConfirmTrashModal() {
      this.isConfirmTrashModalOpen = false;
    },

    viewUser(user) {
      this.$router.push("/dashboard/users/view/" + user.id);
    },

    async loadCompany() {
      try {
        this.isLoading = true;
        this.error = false;
        this.company = await companiesModel.get(this.$route.params.id);

        if (this.isCollab) {
          const rights = this.company.collaborators.find(
            (collab) => collab.id === this.self.id
          );

          if (rights === undefined || !rights.can_modify_infos) {
            return await this.$router.push("/dashboard/setting/companies/");
          }
        }

        this.currentTab = "tab-info";

        this.onInfosCancel();
        this.onGroupesCancel();
        this.onCollabsCancel();
        this.onUsersCancel();

        this.canShowToastedMessages = true;

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.error = true;

        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    showTab(tabId) {
      return !this.isLoading && !this.error && tabId === this.currentTab;
    },
  },
};
</script>

<style lang="scss" scoped>
.company-update {
  &__card {
    padding: 0 25%;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  padding: 40px;

  .md-subheader {
    min-height: 24px;
    margin-top: 4px;
  }

  .md-layout-item {
    margin-top: 20px;
  }
}
</style>
